<template>
    <div class="maincontent">
        <div class="container">
            <div class="row d-flex justify-content-center description mt-4">
                <div class="col-12 col-md-5 order-1 order-md-0 pt-4">
                    <h2 class="font-italic mb-5"> <u>Impressum</u> </h2>
                    <h4 class="mb-4">Informationspflicht laut § 5 TMG.</h4>
                    <p class="mb-4">
                        Thiemecke GbR <br>
                        Sottorfallee 25, <br>
                        22529 Hamburg, <br>
                        Deutschland <br>
                        Wirtschafts-ID: 45/ 662/ 01904 <br>
                        Tel.: 01514 2053283<br>
                        E-Mail: info@jv-living.de <br>
                        Vertretungsberechtigt: Joachim Thiemecke <br>
                    </p>

                    <h4 class="mb-4"><u>EU-Streitschlichtung</u></h4>
                    <p class="mb-4">
                        Gemäß Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten (ODR-Verordnung) möchten wir Sie 
                        über die Online-Streitbeilegungsplattform (OS-Plattform) informieren.
                        Verbraucher haben die Möglichkeit, Beschwerden an die Online Streitbeilegungsplattform der Europäischen 
                        Kommission unter <a href="http://ec.europa.eu/odr?tid=321240248" style="color:#212529">http://ec.europa.eu/odr?tid=321240248</a>  zu richten. Die dafür notwendigen Kontaktdaten finden Sie oberhalb in unserem Impressum.
                        Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder verpflichtet sind, an 
                        Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
                    </p>

                    <h4 class="mb-4"> <u>Haftung für Inhalte dieser Website</u> </h4>
                    <p class="mb-4">
                        Wir entwickeln die Inhalte dieser Webseite ständig weiter und bemühen uns 
                        korrekte und aktuelle Informationen bereitzustellen. Laut Telemediengesetz (TMG) §7 (1) sind wir als Diensteanbieter für eigene Informationen, 
                        die wir zur Nutzung bereitstellen, nach den allgemeinen Gesetzen verantwortlich. Leider können wir keine Haftung für die Korrektheit aller Inhalte 
                        auf dieser Webseite übernehmen, speziell für jene die seitens Dritter bereitgestellt wurden. Als Diensteanbieter im Sinne der §§ 8 bis 10 sind wir nicht 
                        verpflichtet, die von ihnen übermittelten oder gespeicherten Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige 
                        Tätigkeit hinweisen.
                        Unsere Verpflichtungen zur Entfernung von Informationen oder zur Sperrung der Nutzung von 
                        Informationen nach den allgemeinen Gesetzen aufgrund von gerichtlichen oder behördlichen Anordnungen bleiben 
                        auch im Falle unserer Nichtverantwortlichkeit nach den §§ 8 bis 10 unberührt.
                        Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen, bitte wir Sie uns umgehend zu kontaktieren, 
                        damit wir die rechtswidrigen Inhalte entfernen können. Sie finden die Kontaktdaten im Impressum.
                    </p>

                    <h4 class="mb-4"> <u>Haftung für Links auf dieser Website</u> </h4>
                    <p class="mb-4">
                        Unsere Webseite enthält Links zu anderen Webseiten für deren Inhalt wir nicht verantwortlich sind. Haftung für verlinkte 
                        Websites besteht für uns nicht, da wir keine Kenntnis rechtswidriger Tätigkeiten hatten und haben, uns solche Rechtswidrigkeiten
                        auch bisher nicht aufgefallen sind und wir Links sofort entfernen würden, wenn uns Rechtswidrigkeiten bekannt werden.
                        Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitte wir Sie uns zu kontaktieren. Sie finden die Kontaktdaten im Impressum.
                        Urheberrechtshinweis
                        Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos) unterliegen dem Urheberrecht der Bundesrepublik Deutschland. 
                        Bitte fragen Sie uns bevor Sie die Inhalte dieser Website verbreiten, vervielfältigen oder verwerten wie zum Beispiel auf
                        anderen Websites erneut veröffentlichen. Falls notwendig, werden wir die unerlaubte Nutzung von Teilen der Inhalte unserer Seite rechtlich verfolgen.
                        Sollten Sie auf dieser Webseite Inhalte finden, die das Urheberrecht verletzen, bitten wir Sie uns zu kontaktieren.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import { VueperSlides, VueperSlide } from 'vueperslides'
// import 'vueperslides/dist/vueperslides.css'
export default {
  name: 'Impressum',
  components: {
    // VueperSlides, VueperSlide
  },
    data (){
        return {
            search: true,
            filter: '',
            selectOption: '',
            selectLength: '',
            slides:[
                {
                    image:"JVS90_Perspektiven.jpg",
                    title: 'Slide 1',
                    content: 'Slide 1 content.'
                }
            ]
        }
    },
    methods:{
        
    },
    computed:{
        
    },
    created(){
    }

}
</script>

<style scoped>
.description{
    font-size: 1.2rem;
}
</style>